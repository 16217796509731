import React from 'react';

import {
    Collapse,
    Navbar as ReactstrapNavbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
    NavbarText
} from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons' // <-- import styles to be used


class Navigation extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            isOpen: false
        }

        this.toggle = this.toggle.bind(this)
    }

    toggle()
    {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    isLinkActive(urlCheck)
    {
        const pathname = window.location.pathname;
        const hash  = window.location.hash;

        var result = '/'
        if (!hash || hash.length === 0)
        {
            result = pathname
        }

        if (hash.length > 2 && pathname === "/")
        {
            result = `/${hash}`
        }

        return result === urlCheck

    }

    render()
    {
        const {isOpen} = this.state;
        // <NavbarBrand href="/"><img src="/img/logo.png" alt="logo"></img></NavbarBrand>
        return(
            <ReactstrapNavbar expand="md" fixed="top" style={{maxWidth: "100vw"}}>
                    <NavbarToggler onClick={this.toggle} className="ml-2" />
                    <Collapse isOpen={isOpen} navbar>
                        <div style={{marginLeft: 10}}>
                            <NavbarText style={{ width: 100, fontSize: 12, fontWeight: 500}}><FontAwesomeIcon icon={faInstagram} color={'#e6ac00'} /><a href="https://www.instagram.com/rentorama.bv" target='_blank' rel="noreferrer" style={{color: "white"}}> rentorama.bv</a></NavbarText>
                            <NavbarText style={{ width: 150, fontSize: 12, fontWeight: 500}}><FontAwesomeIcon icon="phone-alt" color={'#e6ac00'} /> +32 (0) 468 27 10 56</NavbarText>
                            <NavbarText style={{ width: 350, fontSize: 12, fontWeight: 500}}><FontAwesomeIcon icon="location-arrow" color={'#e6ac00'} /> Pastorijstraat 14/1 , 3530 Houthalen-Helchteren</NavbarText>
                        </div>
                    <Nav navbar color="red" className="ml-auto" style={{marginRight: 30}}>
                            <NavItem>
                                <NavLink active={this.isLinkActive('/')} href="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={this.isLinkActive('/huren')} href="/huren">Wagens</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={this.isLinkActive('/terms')} href="/terms">Algemene voorwaarden</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={this.isLinkActive('/#contact')} href="/#contact">Contact</NavLink>
                            </NavItem>
                    </Nav>
                    </Collapse>
            </ReactstrapNavbar>
        )
    }

}

export default Navigation;
