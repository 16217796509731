import axios from 'axios';
import { apiConstants } from './endpoints';

export const mailService = {
    webMessage, addBooking
};


function webMessage(form)
{
    return axios.post(apiConstants.WEB_MESSAGE, form);
}

function addBooking(form)
{
    return axios.post(apiConstants.ADD_BOOKING, form);
}