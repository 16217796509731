import React from 'react';

import {Row, Col} from 'reactstrap';
import {
    Form,
    Input,
    Checkbox,
    Button,
    List,
    Popover,
    Result,
    message,
  } from 'antd';

import PriceTable from './PriceTable';
import PriceTableMobilhome from './PriceTableMobilhome';
import PriceDisclaimer from './PriceDisclaimer';
import {mailService} from '../api/mail.api';
import { carService } from '../api/car.api';
const { TextArea } = Input;

class UserInfoForm extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            sendingBooking: false,
            bookingError: false,
            bookingDone: false,
        }
    }

    componentDidMount()
    {
        const selected_car = this.props.booked_car ? this.props.booked_car.car : null;
        const {start_date, end_date} = this.props.booking_info? this.props.booking_info: null;
        carService.calculate(start_date, end_date, selected_car.id).then(result => {
            console.log(result)
        }).catch(error => {
            console.log(error)
        })
    }

    onFinish = (values) => {
        const selected_car = this.props.booked_car ? this.props.booked_car.car : null;
        const {start_date, end_date} = this.props.booking_info? this.props.booking_info: null;
        const category = this.props.category ? this.props.category: null;

        this.setState({sendingBooking: true});

        mailService.addBooking(
            {
                "email": values.email,
                "first_name": values.first_name,
                "last_name": values.last_name,
                "phone": values.phone,
                "category": category,
                "selected_id": selected_car.id,
                "start_date": start_date.toISOString(),
                "end_date": end_date.toISOString(),
                "remark": values.remark
            }
        ).then(result => {
            this.setState({
                sendingBooking: false,
                bookingError: false,
                bookingDone: true,
            })
        }).catch(error => {
            this.setState({
                sendingBooking: false,
                bookingDone: false
            })
            message.error("Er ging iets mis met het doorsturen van jouw aanvraag, probeer je opnieuw?")
        })
        
    };

    PriceComponent = (category, item) => {
        if (category !== 'mobilhome') {
        return (<PriceTable prices={item.prices} distance={item.distances} /> )
        }
        else{
            return(<PriceTableMobilhome prices={item.prices} distance={this.props.booked_car.distances} />)
        }
    }

    getDescriptionComponent(item) {
        return <div>
            <Row>
                <Col lg={12}>
                    {this.props.category !== "mobilhome" ? <p className={"price"}>* €{item.prices.day}<span>/dag</span> </p> : <p className="price">Tarieven per seizoen.</p>}
                </Col>
                <Col lg={6}>
                    <p>
                        {item.transmission}<br />
                        {item.fuel}
                    </p>
                </Col>
                <Col lg={6}>
                    {item.doors &&
                        <p>
                            {item.doors}<span> Deuren</span><br />
                            {item.seats}<span> Plaatsen</span>
                        </p>
                    }
                </Col>
            </Row>
            <Row>
                <Col lg={10}>
                        <Popover
                            placement="top"
                            content={this.PriceComponent(this.props.category, item)}
                            trigger="click"
                        >
                            <a href="#">Tarieven</a>
                    </Popover> | <Popover placement="right" title="Waarborg" content={`€ ${item.guarantee}`} trigger="click">
                        <a href="#">Waarborg</a>
                    </Popover>
                </Col>
            </Row>
        </div>;
    }

    render()
    {
        const formItemLayout = {
            labelCol: {
              xs: {
                span: 24,
              },
              sm: {
                span: 4,
              },
            },
            wrapperCol: {
              xs: {
                span: 24,
              },
              sm: {
                span: 24,
              },
            },
          };
        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            },
        };

        const selected_car = this.props.booked_car ? this.props.booked_car.car : null;
        const {start_date, end_date} = this.props.booking_info? this.props.booking_info: null;
        console.log(start_date.toISOString())
        console.log(end_date.toISOString())
        console.log(selected_car)

        if(this.state.bookingDone) {
            return(
                <div style={{fontFamily: 'Raleway'}}>
                <Row className={'justify-content-center'}>
                <Col lg={12}>
                <Result
                    status="success"
                    title="Huuraanvraag Ontvangen"
                    subTitle="Bedankt voor jouw huuraanvraag, we hebben het goed ontvangen! Ons team zal zo snel mogelijk contact opnemen met jou."
                />
                </Col>
                </Row>
                </div>
            )
        }

        return(
            <div style={{fontFamily: 'Raleway'}}>
            <Row className={'justify-content-center'}>
                <Col lg={12}>
                    <h3>Jouw persoonlijke gegevens</h3><br />
                </Col>
                <Col lg={8}>
                    {selected_car &&
                        <List
                        itemLayout="vertical"
                        bordered
                        dataSource={selected_car ? [selected_car]: []}
                        renderItem={item => (
                            <List.Item
                                key={item.id}
                                extra={<img
                                    width={272}
                                    alt="logo"
                                    src={item.image} />}
                            >
                                <div style={{ textAlign: 'left' }}>
                                    <List.Item.Meta
                                        title={item.name}
                                        description={this.getDescriptionComponent(item)} />
                                </div>
                                {item.content}
                            </List.Item>
                        )} />
                    }
                </Col>
            </Row>
            <Row style={{marginTop: '30px'}} className={'justify-content-center'}>
                <Col lg={8}>
                <Form
                    {...formItemLayout}
                    name="register"
                    onFinish={this.onFinish}
                    scrollToFirstError
                    >
                    <p>{`Ophalen op ${start_date.format("LLL")}`}</p>
                    <p>{`Terugbrengen op ${end_date.format("LLL")}`}</p>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                        {
                            type: 'email',
                            message: 'Dit is geen geldige e-mail.',
                        },
                        {
                            required: true,
                            message: 'Vul uw e-mail in.',
                        },
                        ]}
                    >
                        <Input disabled={this.state.sendingBooking} />
                    </Form.Item>

                    <Form.Item
                        name="first_name"
                        label="Voornaam"
                        rules={[
                        {
                            required: true,
                            message: 'Vul uw voornaam in.',
                            whitespace: true,
                        },
                        ]}
                    >
                        <Input disabled={this.state.sendingBooking} />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Achternaam"
                        rules={[
                        {
                            required: true,
                            message: 'Vul uw achternaam in.',
                            whitespace: true,
                        },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="Telefoon"
                        rules={[
                        {
                            required: true,
                            message: 'Vul uw telefoonnummer in.',
                        },
                        ]}
                    >
                        <Input
                            disabled={this.state.sendingBooking}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="remark"
                        label="Opmerking"
                        rules={[
                        {
                            required: false,
                            message: 'Een speciaal verzoek? Laat het ons hier weten.',
                        },
                        ]}
                    >
                        <TextArea
                            disabled={this.state.sendingBooking}
                            placeholder="Een speciaal verzoek? Laat het ons hier weten. Lever- en ophaalservice? Luchthaven vervoer?"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                        {
                            validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject('U dient onze algemene voorwaarden en privacybeleid te accepteren.'),
                        },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            U begrijpt dat de reservatie pas definitief is na een bevestiging van jouw aanvraag door ons team. Door gebruik te maken van onze diensten gaat u akkoord met onze <a href="/terms">Algemene Voorwaarden</a> en <a href="/privacy">Privacybeleid</a>.
                        </Checkbox>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Button style={{ marginRight: '10px' }} onClick={() => this.props.prev()}>{"< Terug"}</Button>
                        <Button loading={this.state.sendingBooking} type="primary" htmlType="submit">
                            Aanvraag Indienen
                        </Button>
                    </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row className={"justify-content-center"}>
                <Col lg={8}>
                        <PriceDisclaimer />
                </Col>
            </Row>
            </div>
        )
    }
}

export default UserInfoForm;