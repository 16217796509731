/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {Fragment} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useParams,
} from 'react-router-dom';

import Home from './Home';
import Navigation from '../components/Navigation';
import Wizard from './Wizard';
import Privacy from './Privacy';
import Terms from './Terms';
import StandardPage from './StandardPage';
import { ReservationContextProvider } from '../context/reservation.context';

function Rent() {
    // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { category } = useParams();

  return (
    <ReservationContextProvider>
        <StandardPage content={<Wizard category={category}/>}/>
    </ReservationContextProvider>
  );
}


class Main extends React.Component {

    render()
    {
        return (
                <div>
                    <Navigation />
                <Router>
                    <Fragment>
                        <Switch>
                            <Route exact path={"/"}><Home /></Route>
                            <Route path={"/huren/:category?"} children={<Rent />} />
                            <Route path={"/privacy"}><StandardPage content={<Privacy />}/></Route>
                            <Route path={"/terms"}><StandardPage content={<Terms />}/></Route>
                            <Redirect from={'/'} to={'/'} key={'default'} />
                        </Switch>
                    </Fragment>
                </Router>
           </div>
        )
    }
}

export default Main
