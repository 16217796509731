import React from 'react';
import {
    Row,
    Col,
} from "reactstrap";

import { Card } from "antd";

const Meta = Card.Meta;
const col_size = 6;
const img_height = "8vw";

class CategoryPicker extends React.Component
{

    render()
    {
        return(
                <div>
                <Row style={{padding: 30}} className={'justify-content-center'}>
                    {this.props.showTitle && <Col lg={12}>
                        <h3>Kies een categorie van wagen</h3><br />
                        <hr />
                    </Col>}
                    <Col lg={col_size}>
                        <Card
                            onClick={()=>this.props.updateCategoryInfo('general')}
                            hoverable
                            cover={
                                <img
                                style={{width: "100%",
                                        height: img_height,
                                        objectFit: "cover"}}
                                  alt="example"
                                  src="/img/nissan_driving.webp"
                                />
                            }
                        >
                            <Meta title="Personenwagen" />
                        </Card>
                    </Col>
                    <Col lg={col_size}>
                        <Card
                            onClick={()=>this.props.updateCategoryInfo('premium')}
                            hoverable
                            cover={
                                <img
                                style={{width: "100%",
                                        height: img_height,
                                        objectFit: "cover"}}
                                  alt="example"
                                  src="/img/golf-r8.jpg"
                                />
                            }
                        >
                            <Meta title="Premium Wagen" />
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CategoryPicker;