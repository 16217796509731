import axios from 'axios';
import { apiConstants } from './endpoints';

export const carService = {
    getCars,
    calculate,
};


function getCars()
{
    return axios.get(apiConstants.GET_CARS);
}

function calculate(startDate, endDate, carId)
{
    return axios.get(`${apiConstants.CALCULATE}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&carId=${carId}`, {startDate, endDate, carId})
}