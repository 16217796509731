import React from 'react';
import {Table} from 'antd';

const columns = [
    {
        title: 'Periode',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Prijs',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Inbegrepen KMs',
        dataIndex: 'included',
        key: 'included',
    }
];

class PriceTable extends React.Component
{

    transformPricesToData(prices, distances)
    {
        return [
            {
                key: 'dag',
                time: "Dag",
                price: `€ ${prices.day}`,
                included: `${distances.day} KM`
            },
            {
                key: 'weekend',
                time: "Weekend",
                price: `€ ${prices.weekend}`,
                included: `${distances.weekend} KM`
            },
            {
                key: 'week',
                time: "Week",
                price: `€ ${prices.week}`,
                included: `${distances.week} KM`
            },
            {
                key: 'month',
                time: "Maand",
                price: `€ ${prices.month}`,
                included: `${distances.month} KM`
            }
        ]
    }

    render()
    {
        const data = (this.props.prices && this.props.distance) ? this.transformPricesToData(this.props.prices, this.props.distance) : [];

        return(
            <div>
                <Table
                    size={"small"}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    footer={() => {<p>€ 350 Waarborg</p>}}
                    />
            </div>
        )
    }
}

export default PriceTable;
