import React from 'react';
import MyFooter from '../components/MyFooter';
import Navigation from '../components/Navigation';


class StandardPage extends React.Component {

    render()
    {

        return(
            <div>
                    <Navigation />
                    {this.props.content}
                    <MyFooter fixed={true}/>
            </div>
        )
    }
}

export default StandardPage;