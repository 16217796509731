import React, { Component } from "react";
const { Provider, Consumer } = React.createContext();

// Note: You could also use hooks to provide state and convert this into a functional component.
class ReservationContextProvider extends Component {
  state = {
    booking_info: null,
    car_booked : null,
    user_info: null,
  };

  addBookingInfo = (booking_info) => {
    this.setState({
      booking_info
    });
  };

  addSelectedCar = (car_booked) => {
    this.setState({
      car_booked
    });
  };

  addUserInfo = (user_info) => {
    this.setState({
      user_info
    });
  };


  render() {
    return (<Provider value={{
                                info: this.state,
                                addBookingInfo: this.addBookingInfo,
                                addSelectedCar: this.addSelectedCar,
                                addUserInfo: this.addUserInfo,
                            }}>
                {this.props.children}
            </Provider>);
  }
}

export { ReservationContextProvider, Consumer as ReservationContextConsumer };