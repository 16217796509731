import React from 'react';
import Container from 'reactstrap/lib/Container';

import {Row, Col } from 'reactstrap';


class Terms extends React.Component
{
    render()
    {
        return(
            <Container style={{marginTop: 100, paddingBottom: 100}}>
                <Row>
                    <Col className="col-xl-12 col-md-12">
                        <h4>Algemene Voorwaarden - Verhuur Voertuig</h4>
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col className="md-col-12">
                    <p>Onze volledige algemene voorwaarden kan je <a href="/files/algemenevoorwaarden_verhuur_rentorama.pdf" target="_blank" style={{color: '#e6ac00'}}>hier raadplegen</a>. Hieronder volgen alvast enkele huisregels.</p>
                    <h4>Waarborgen</h4>
                    <p>De betaling van de waarborgen kunnen uitgevoerd worden met een kredietkaart, bankkaart, overschrijving of in contanten. Hiervan ontvangt de huurder een ontvangstbewijs</p>
                    <h4>Schoonmaak</h4>
                    <p>Voor elke voertuig geld dat bij extreme vervuiling er extra kosten voor de schoonmaak worden aangerekend. Het bedrag hangt af van de graad van vervuiling (prijs varieert tussen 45€ en 300€).</p>
                    <h4>Brandstof</h4>
                    <p>Brandstofverbruik is niet inbegrepen in de verhuurprijs. Het voertuig dient met een volle tank geretourneerd worden. Indien het voertuig door de huurder niet wordt terugbezorgd met een volle tank, zullen de brandstofkosten afgehouden worden van de waarborg.</p>
                    <h4>Schade</h4>
                    <p>Bij het einde van de huur zal het voertuig worden gecontroleerd, en zal de veroorzaakte schade door de verhuurder worden genoteerd op het innameformulier. De huurder zal instaan voor de kosten van de herstellingen. De waarborg wordt volledig ingehouden door de verhuurder. Als de schade hoger is dan de waarborg betaald de huurder het verschil, als de schade lager is dan de waarborg wordt het verschil terugbetaald aan de huurder.</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Terms;