import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Row, Col} from "reactstrap";

class Services extends React.Component {

    render()
    {
        return(
            <section id="diensten" className="carcare-service-area section_70" data-scroll-index="2">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">
                        <div className="site-heading">
                            <h2>Ontdek Onze Diensten</h2>
                            <h4>Veel meer dan enkel verhuur</h4>
                            <hr></hr>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="service-thumb text-center">
                        <div className="thumb-icon"><FontAwesomeIcon icon="glass-cheers" size="3x" color="#e6ac00" /></div>
                            <h4>Feestvervoer</h4>
                            <p>Is het jullie speciale dag of heb je een belangrijk feestje gepland? Laat je veilig en in stijl vervoeren.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="service-thumb text-center">
                            <div className="thumb-icon"><FontAwesomeIcon icon="plane-arrival" size="3x" color="#e6ac00" /></div>
                            <h4>Luchthavenvervoer</h4>
                            <p>Verwacht je een zakenpartner of wil je rustig op reis vertrekken? Wij zorgen voor een aangename en professionele rit van en naar de luchthaven.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="service-thumb text-center">
                            <div className="thumb-icon"><FontAwesomeIcon icon="location-arrow" size="3x" color="#e6ac00" /></div>
                            <h4>Lever- en ophaaldienst</h4>
                            <p>Geen tijd of zin om de wagen te komen halen of af te leveren? Geen probleem! Wij komen tot bij jou!</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="service-thumb text-center">
                            <div className="thumb-icon"><FontAwesomeIcon icon="taxi" size="3x" color="#e6ac00" /></div>
                            <h4>Taxi Service</h4>
                            <p>Geen zin om zelf te rijden? Geen probleem! Wij hebben professionele chauffeurs voor je klaarstaan die je veilig en comfortabel van A naar B brengen.</p>
                        </div>
                    </div>
                    </div>
                    <Row style={{textAlign: 'center', marginTop: 50}}>
                        <Col>
                            <p style={{fontWeight: 500}}><a style={{fontWeight: 500, color: '#e6ac00'}} href="/#contact">Contacteer ons </a>vrijblijvend met al jouw vragen of wensen.</p>
                        </Col>
                    </Row>
                </div>
            </section>
        )
    }
}

export default Services