import React from 'react';


class MyFooter extends React.Component {

    render()
    {
        return (
            <footer className= {this.props.fixed ? "text-center fixed-bottom" : "text-center"}>
                <div className="footer">
                    <p>&copy; 2022, Rentorama. Alle rechten voorbehouden. | <a href="/terms">Algemene Voorwaarden</a> | <a href="/privacy">Privacybeleid</a></p>
                </div>
            </footer>
        )
    }
}

export default MyFooter;
