import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Card } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const Meta = Card.Meta;


class CategoryPickerBannerMobile extends React.Component
{

    render()
    {
        return(
            // <Carousel showThumbs={false}>
            //     <Link style={{ textDecoration: 'none' }} to={{pathname: `/huren/general`}}>
            //         <Card
            //             hoverable={true}
            //             cover={
            //                 <img
            //                 style={{width: "100%",
            //                         height: "50vw",
            //                         objectFit: "cover"}}
            //                     alt="Nissan Micra"
            //                     src="/img/nissan_driving.webp"
            //                 />
            //             }
            //         >
            //             <Meta title="Personenwagen" />
            //         </Card>
            //     </Link>
            //     <Link style={{ textDecoration: 'none' }} to={{pathname: `/huren/premium`}}>
            //         <Card
            //             hoverable={true}
            //             cover={
            //                 <img
            //                 style={{width: "100%",
            //                         height: "50vw",
            //                         objectFit: "cover"}}
            //                 alt="Audi Q7"
            //                 src="/img/golf-r8.jpg"
            //                 />
            //             }
            //         >
            //             <Meta title="Premium Wagen" />
            //         </Card>
            // </Link>
            //     <Link style={{ textDecoration: 'none' }} to={{pathname: `/huren/light_cargo`}}>
            //         <Card
            //             hoverable={true}
            //             cover={
            //                 <img
            //                     style={{width: "100%",
            //                             height: "50vw",
            //                             objectFit: "cover"}}
            //                     alt="example"
            //                     src="/img/boxer_driving.webp"
            //                 />
            //             }
            //         >
            //             <Meta title="Lichte Vracht" />
            //         </Card>
            //     </Link>
            // </Carousel>
            <Link  to={{pathname: `/huren`}}>
                <a style={{background: '#e6ac00', color: 'white', fontSize: "5vw", padding: 10, borderRadius: 10}}><span>Ontdek onze wagens</span></a>
                {/* <Button style={{background: '#e6ac00', color: 'white'}}>Ontdek onze wagens</Button> */}
            </Link>
        )
    }
}

export default CategoryPickerBannerMobile;