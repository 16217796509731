import React from 'react';

import moment from 'moment';
import {Row, Col, Form, FormGroup} from 'reactstrap';
import { DatePicker, message, Select, Popover, Card } from 'antd';
import locale from 'antd/es/date-picker/locale/nl_BE';
import { List, Button } from 'antd';
import {carService} from '../api/car.api';
import PriceTable from './PriceTable';
import PriceTableMobilhome from './PriceTableMobilhome';
import PriceDisclaimer from './PriceDisclaimer';
import MediaQuery from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



locale.lang['ok'] = 'Kies';
locale.lang.rangePlaceholder[0] = 'Ophaaldatum';
locale.lang.rangePlaceholder[1] = 'Inleverdatum';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Meta } = Card;


const BOOKING_START_DATE_DELTA = 1 // days you need to book in advance.

class ReservationPicker extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            startDate: this.getStartDate(),
            endDate: null,
            cars: [],
            loading_cars: true,
        }
        this.carousel = React.createRef();
        this.disabledDate = this.disabledDate.bind(this)
        this.getDisabledMinutes = this.getDisabledMinutes.bind(this)
        this.disabledDateTime = this.disabledDateTime.bind(this)
        this.onCategoryChange = this.onCategoryChange.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.getCarCard = this.getCarCard.bind(this);
    }

    componentDidMount()
    {
        carService.getCars()
            .then(result => result.data)
            .then(data => {
                this.setState({
                    loading_cars: false,
                    cars: data.cars,
                })
                console.log(data)
            })
            .catch(error => {
                this.setState({
                    loading_cars: false
                })
                message.error('Er ging iets fout bij het laden van de wagens.')
            })
            .finally(() =>{
                this.setState({
                    loading_cars: false
                })
            })
    }

    getStartDate()
    {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + BOOKING_START_DATE_DELTA)
        return tomorrow
    }

    disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    getDisabledMinutes()
    {
        const result = [];
        for (let i = 0; i < 60; i++) {
          if ((i % 15) !== 0) {result.push(i)};
        }
        return result;
    }

    disabledDateTime() {
        return {
          disabledHours: () => [],
          disabledMinutes: () => this.getDisabledMinutes(),
          disabledSeconds: () => [],
        };
      }

    handleChange(moment)
    {

        if (moment && moment[1].diff(moment[0], 'hours') <= 3)
        {
            message.error("U dient een wagen te huren voor minstens 4 uur.")
            return
        }

        this.props.updateBookingInfo({
            start_date: moment ? moment[0] : null,
            end_date: moment ? moment[1]: null,
            days: moment ? moment[1].diff(moment[0], 'days'): null,
        })
    }

    onCategoryChange(value)
    {
        this.props.updateCategoryInfo(value)
    }

    getCarCard(item, onMobile)
    {
        const objFit = onMobile ? "cover": "scale-down"
        const height = onMobile ? "40vw": "10vw"

       return(
                <div style={{paddingBottom: 50}}>
                <Card
                    key={`card-${item.id}`}
                    hoverable
                    style={{height: '100%', width: '100%'}}
                    cover={
                    <img
                            style={{width: "100%",
                            height: height,
                            objectFit: objFit}}
                            alt={item.name}
                            src={item.image}
                    />
                    }
                    actions={[
                        <Button style={{marginLeft: 5}} type={"primary"} size={"small"} onClick={() => { this.props.updateCarBooked(item, item.distances); } }>Kies</Button>,
                        <Popover
                            placement="top"
                            content={this.props.info.category !== "mobilhome" ? <PriceTable prices={item.prices}
                            distance={item.distances} /> : <PriceTableMobilhome prices={item.prices} distance={item.distances} />}
                            trigger="click"
                        >
                            <a href="#">Tarieven</a>
                        </Popover>,
                        <Popover placement="right" title="Waarborg" content={`€ ${item.guarantee}`} trigger="click">
                            <a href="#">Waarborg</a>
                        </Popover>
                    ]}
                >
                    <Meta
                    title={item.name}
                    description={this.getDescriptionComponent(item)}
                    />
                </Card>
                </div>
       )
    }

    render()
    {
        const data = this.state.cars && this.props.info.category ? this.state.cars[this.props.info.category] : null

        const carList = <List
                            grid={{
                                gutter: { xs: 8, sm: 16, md: 4, lg: 2, xl: 2},
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 3,
                                xl: 3,
                                xxl: 3,
                            }}
                            loading={this.state.loading_cars}
                            itemLayout="vertical"
                            bordered={false}
                            dataSource={data}
                            renderItem={item => (
                                                    <List.Item>
                                                        {this.getCarCard(item, false)}
                                                    </List.Item>
                                                )} />;

        const carouselComp = <Carousel ref={this.carousel} showThumbs={false} showArrows={false}>
                            {data && data.map((item) => {
                                return(
                                    <div key={item.id}>
                                        {this.getCarCard(item, true)}
                                    </div>
                                )
                            })}
                        </Carousel>

        return(
            <div>
            <Row form className={'justify-content-center '}>
                <Col lg={12} md={12}>
                    <Form inline style={{width: '100%'}}>
                        <FormGroup >
                                <RangePicker
                                    autoFocus={true}
                                    locale={locale}
                                    disabledDate={this.disabledDate}
                                    disabledTime={this.disabledDateTime}
                                    showTime={{
                                        hideDisabledOptions: true,
                                    }}
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={this.handleChange}
                                    style={{marginRight: '10px'}}
                                    value={[this.props.info.start_date, this.props.info.end_date]}
                                />
                        </FormGroup>
                        <FormGroup >
                                <Select
                                    placeholder="Kies een voertuig categorie"
                                    onChange={this.onCategoryChange}
                                    allowClear
                                    value={this.props.info.category ? this.props.info.category : null}
                                >
                                    <Option value="general">Personenwagen</Option>
                                    <Option value="premium">Premium wagen</Option>
                                </Select>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            {this.props.info.category && <Row className={'justify-content-center'} style={{marginTop: '10px', paddingBottom: '50px'}}>
                <Col lg={12}>
                    <MediaQuery minDeviceWidth={1224}>
                        {carList}
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={1224}>
                        {carouselComp}
                    </MediaQuery>
                </Col>
            </Row>}
            <Row className={'justify-content-center'}>
                <Col lg={11}>
                    <PriceDisclaimer />
                </Col>
            </Row>
            </div>
        )
    }

    getDescriptionComponent(item) {
        return <div style={{fontFamily: 'Raleway'}}>
                    <Row>
                        <Col lg={6} md={2} sm={2}>
                            <p style={{textAlign: 'left'}}>
                                {item.transmission}<br />
                                {item.fuel}
                            </p>
                        </Col>
                        <Col lg={6} md={2} sm={2}>
                            {item.doors &&
                                <p style={{textAlign: 'right'}}>
                                    {item.doors}<span> Deuren</span><br />
                                    {item.seats}<span> Plaatsen</span>
                                </p>
                            }
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg={6}>
                            {this.props.info.category !== "mobilhome" ? <p className={'price'}>*Vanaf €{item.prices.day} per dag
                            <br /><span> (incl. BTW)</span>
                            </p> :
                            <p className={'price'}>Tarieven per seizoen.
                            </p>
                            }
                        </Col>
                        <Col lg={6}>
                            <p className="distance">{`${item.distances.day ?? 140} km gratis`}<br />
                            <span>€{`${item.extra_km} / extra km`}</span>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {item.description && <p className={'description'}> {item.description}
                            </p>
                            }
                        </Col>
                    </Row>
                </div>;
    }
}

export default ReservationPicker;