import React  from 'react';


class PriceDisclaimer extends React.Component{

    render()
    {
        return (
            <p style={{
                fontFamily: 'Raleway',
                color: 'grey',
                fontSize: '10px'
            }}>
                * De foto's dienen ter illustratie en zijn niet bindend. De verhuurprijzen zijn exclusief brandstof. U krijgt de wagen met een volle tank en u levert de wagen opnieuw in met een volle tank. Voor meer informatie verwijzen we u graag door naar onze <a href={"/terms"} target="_blank" rel="noreferrer">Algemene Voorwaarden</a>.
            </p>
        )
    }
}

export default PriceDisclaimer;