import React from 'react';

import {Container} from 'reactstrap';
import {Steps, message} from 'antd';
import ReservationPicker from '../wizard/ReservationPicker';
import UserInfoForm from '../wizard/UserinfoForm';
import CategoryPicker from '../wizard/CategoryPicker';


const { Step } = Steps;

class Wizard extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            current: 0,
            reservation_info: {
              user_info: {
                mail: null,
                first_name: null,
                last_name: null,
                telephone: null,
                address: null,
              },
              booking_info: {
                start_date: null,
                end_date: null,
                days: null
              },
              category: null,
              booked_car: null
            }
        }

        this.setCurrent = this.setCurrent.bind(this);
        this.updateBookingInfo = this.updateBookingInfo.bind(this);
        this.updateCategoryInfo = this.updateCategoryInfo.bind(this);
        this.updateCarBooked = this.updateCarBooked.bind(this);

        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
    }

    setCurrent(step)
    {
        this.setState({
            current: step
        })
    }

    showNext()
    {
      if (this.state.current === 0 && this.state.reservation_info.category)
      {
        return true;
      }

      if (this.state.current === 1)
      {
        const info = this.state.reservation_info;

        if (!info.booking_info ||
          !info.booking_info.start_date ||
          !info.booking_info.end_date ||
          !info.booking_info.days)
          {
            message.error("Kies een geldige huurperiode.")
            return false;
          }

        return true;
      }

      return false;
    }

    updateBookingInfo(info)
    {
      this.setState(prevState => ({
        reservation_info: {
            ...prevState.reservation_info,
            booking_info: info
        }
    }))
    }

    updateCategoryInfo(cat)
    {
      this.setState(prevState => ({
          reservation_info: {
              ...prevState.reservation_info,
              category: cat
          }
      }))

      if(this.state.current === 0) {this.next()}

    }

    updateCarBooked(car, distance)
    {
      this.setState(prevState => ({
        reservation_info: {
            ...prevState.reservation_info,
            booked_car: { car: car}
        }
      }))

      this.next();
    }

    next = () => {

      if (this.showNext())
      {
        this.setCurrent(this.state.current + 1);
      }
    };

    prev = () => {
      this.setCurrent(this.state.current - 1);
    };

    componentDidUpdate()
    {
      if (!this.props.category) { return; }
      const {category} = this.props.category;

      if (category)
      {
        this.updateCategoryInfo(category)
      }
    }

    render()
    {
        const current = this.state.current;

        if (this.props.category && current === 0)
        {
          this.updateCategoryInfo(this.props.category)
        }

        const steps = [
          {
            title: "Categorie Kiezen",
            content: <CategoryPicker updateCategoryInfo={(cat) => {this.updateCategoryInfo(cat)}}/>
          },
          {
            title: 'Huur Opties',
            content: <ReservationPicker
                        prev={this.prev}
                        info={{
                                category: this.state.reservation_info.category,
                                start_date: this.state.reservation_info.booking_info.start_date,
                                end_date: this.state.reservation_info.booking_info.end_date}
                              }
                        updateBookingInfo={(info) => {this.updateBookingInfo(info)}}
                        updateCategoryInfo={(cat) => {this.updateCategoryInfo(cat)}}
                        updateCarBooked={(car, distance) => {this.updateCarBooked(car, distance)}}
                        />,
          },
          {
            title: 'Reservatie Aanvragen',
            content: <UserInfoForm
                        prev={this.prev}
                        category={this.state.reservation_info.category}
                        booking_info={{
                          start_date: this.state.reservation_info.booking_info.start_date,
                          end_date: this.state.reservation_info.booking_info.end_date,
                        }}
                        booked_car = {this.state.reservation_info.booked_car}
                    />,
          },
        ];

        return(
            <div>
                  <Container style={{marginTop: 140, paddingBottom: 100}}>
                      <Steps responsive={true} current={current}>
                          {steps.map(item => (
                          <Step key={item.title} title={item.title} />
                          ))}
                      </Steps>
                      <div className="steps-content">{steps[current].content}</div>
                  </Container>
            </div>
        )
    }
}

export default Wizard;