import React from 'react';
import {Table, Tabs} from 'antd';

const { TabPane } = Tabs;

const columns = [
    {
        title: 'Periode',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Prijs',
        dataIndex: 'price',
        key: 'price',
    },
    {
        title: 'Inbegrepen KMs',
        dataIndex: 'included',
        key: 'included',
    }
];

class PriceTable extends React.Component
{

    transformPricesToData(prices, distance)
    {
        return [
            {
                key: 'dag',
                time: "Dag",
                price: `€ ${prices.day}`,
                included: `${distance.day_km} KM`
            },
            {
                key: 'weekend',
                time: "Weekend",
                price: `€ ${prices.weekend}`,
                included: `${distance.weekend_km} KM`
            },
            {
                key: 'week',
                time: "Week",
                price: `€ ${prices.week}`,
                included: `${distance.week_km} KM`
            },
            {
                key: 'month',
                time: "Maand",
                price: `€ ${prices.month}`,
                included: `${distance.month_km} KM`
            }
        ]
    }

    getDatesPerSeason(prices, distance)
    {
        const data = {}
        data["mid"] = this.transformPricesToData(prices.mid, distance);
        data["low"] = this.transformPricesToData(prices.low, distance);
        data["high"] = this.transformPricesToData(prices.high, distance);
        return data;
    }

    render()
    {
        const data = (this.props.prices && this.props.distance) ? this.getDatesPerSeason(this.props.prices, this.props.distance) : [];

        return(
            <div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Laag Seizoen" key="1">
                        <p>November t.e.m Maart</p>
                        <Table
                            size={"small"}
                            dataSource={data.low}
                            columns={columns}
                            pagination={false}
                            footer={() => {<p>€ 350 Waarborg</p>}}
                        />
                    </TabPane>
                    <TabPane tab="Tussen Seizoen" key="2">
                        <p>April, Juni, Oktober</p>
                        <Table
                            size={"small"}
                            dataSource={data.mid}
                            columns={columns}
                            pagination={false}
                            footer={() => {<p>€ 350 Waarborg</p>}}
                        />
                    </TabPane>
                    <TabPane tab="Hoog Seizoen" key="3">
                        <p>Juli t.e.m. September</p>
                        <Table
                            size={"small"}
                            dataSource={data.high}
                            columns={columns}
                            pagination={false}
                            footer={() => {<p>€ 350 Waarborg</p>}}
                        />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default PriceTable;
