import React from 'react';
import Container from 'reactstrap/lib/Container';

import {Row, Col } from 'reactstrap';

class Wizard extends React.Component
{
    render()
    {
        return(
            <Container style={{marginTop: 100, paddingBottom: 100}}>
                <Row>
                    <Col className="col-xl-12 col-md-12">
                        <h3>Privacybeleid</h3>
                        <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col className="md-col-12">
                    <p>
                        Deze pagina is voor het laatst aangepast op 20/05/2021.
                    </p>
                    <p>
                    Wij zijn er van bewust dat u vertrouwen stelt in ons. Wij zien het dan ook als onze verantwoordelijkheid om uw privacy te beschermen. Op deze pagina laten we u weten welke gegevens we verzamelen als u onze website gebruikt, waarom we deze gegevens verzamelen en hoe we hiermee uw gebruikservaring verbeteren. Zo snapt u precies hoe wij werken.
Dit privacybeleid is van toepassing op de diensten van Rentorama. U dient zich ervan bewust te zijn dat Rentorama niet verantwoordelijk is voor het privacybeleid van andere sites en bronnen. Door gebruik te maken van deze website geeft u aan het privacy beleid te accepteren. Rentorama respecteert de privacy van alle gebruikers van haar site en draagt er zorg voor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld.
                    </p>
                    <h3>Ons gebruik van verzamelde gegevens</h3>
                    <h4>Gebruik van onze diensten</h4>
                    <p>Wanneer u zich aanmeldt voor een van onze diensten vragen we u om persoonsgegevens te verstrekken. Deze gegevens worden gebruikt om de dienst uit te kunnen voeren. De gegevens worden opgeslagen op eigen beveiligde servers van Rentorama of die van een derde partij. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.</p>
                    <h4>Communicatie</h4>
                    <p>Wanneer u e-mail of andere berichten naar ons verzendt, is het mogelijk dat we die berichten bewaren. Soms vragen wij u naar uw persoonlijke gegevens die voor de desbetreffende situatie relevant zijn. Dit maakt het mogelijk uw vragen te verwerken en uw verzoeken te beantwoorden. De gegevens worden opgeslagen op eigen beveiligde servers van Rentorama of die van een derde partij. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.</p>
                    <h4>Cookies</h4>
                    <p>Wij verzamelen gegevens voor onderzoek om zo een beter inzicht te krijgen in onze klanten, zodat wij onze diensten hierop kunnen afstemmen. Deze website maakt gebruik van “cookies” om de website te helpen analyseren hoe gebruikers de site gebruiken. De door het cookie gegenereerde informatie over uw gebruik van de website kan worden overgebracht naar eigen beveiligde servers van Rentorama of die van een derde partij. Wij gebruiken deze informatie om bij te houden hoe u de website gebruikt, om rapporten over de website-activiteit op te stellen en andere diensten aan te bieden met betrekking tot website-activiteit en internetgebruik.</p>
                    <h4>Doeleinden</h4>
                    <p>We verzamelen of gebruiken geen informatie voor andere doeleinden dan de doeleinden die worden beschreven in dit privacybeleid tenzij we van tevoren uw toestemming hiervoor hebben verkregen.</p>
                    <h4>Derden</h4>
                    <p>De informatie wordt niet met derden gedeeld tenzij nodig voor het uitvoeren van de diensten. Voor onze infrastructuur maken we gebruik van de <a rel="noreferrer" href="https://cloud.google.com/terms/data-processing-terms" target="_blank">Google Cloud</a>, <a rel="noreferrer" href="https://www.twilio.com/legal/data-protection-addendum" target="_blank">Sendgrid</a> en <a rel="noreferrer" href="https://www.netlify.com/gdpr-ccpa/" target="_blank">Netlify</a> omgevingen en verwijzen we naar de respectievelijke data processing agreements. In enkele gevallen kan de informatie intern gedeeld worden. Onze werknemers zijn verplicht om de vertrouwelijkheid van uw gegevens te respecteren.</p>
                    <h4>Gegevens Verzameling</h4>
                    <p>Indien u gebruik maakt van onze diensten, verzamelen we volgende gegevens van u: voornaam, achternaam, telefoonnummer, e-mail en ip-adres. Indien u een huurovereenkomst afsluit vragen we eveneens voor een kopie van jouw identiteitsdocument en rijbewijs.</p>
                    <h4>Veranderingen</h4>
                    <p>Deze privacyverklaring is afgestemd op het gebruik van en de mogelijkheden op deze site. Eventuele aanpassingen en/of veranderingen van deze site, kunnen leiden tot wijzigingen in deze privacyverklaring. Het is daarom raadzaam om regelmatig deze privacyverklaring te raadplegen.</p>
                    <h4>Keuzes voor persoonsgegevens</h4>
                    <p>Wij bieden alle bezoekers de mogelijkheid tot het inzien, veranderen, of verwijderen van alle persoonlijke informatie die op moment aan ons is verstrekt.</p>
                    <h4>Cookies uitzetten</h4>
                    <p>De meeste browsers zijn standaard ingesteld om cookies te accepteren, maar u kunt uw browser opnieuw instellen om alle cookies te weigeren of om aan te geven wanneer een cookie wordt verzonden. Het is echter mogelijk dat sommige functies en ““services, op onze en andere websites, niet correct functioneren als cookies zijn uitgeschakeld in uw browser.</p>
                    <h4>Vragen en feedback</h4>
                    <p>We controleren regelmatig of we aan dit privacybeleid voldoen. Als u vragen heeft over dit privacybeleid, kunt u contact met ons opnemen:<br /><br/>
                    Rentorama <br/>
                    Pastorijstraat 14/1 , 3530 Houthalen-Helchteren<br/>
                    info@rentorama.be</p>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Wizard;
