
import {
    faCar,
    faUser,
    faCogs,
    faPersonBooth,
    faGasPump,
    faShoppingBag,
    faPlaneArrival,
    faGlassCheers,
    faLocationArrow,
    faTaxi,
    faPhoneAlt,
    faArrowCircleRight,
    faArrowCircleLeft
} from "@fortawesome/free-solid-svg-icons";

import {
 faLinkedin
} from "@fortawesome/free-brands-svg-icons"

import { library } from '@fortawesome/fontawesome-svg-core';


library.add(
    faCar,
    faUser,
    faCogs,
    faPersonBooth,
    faGasPump,
    faShoppingBag,
    faPlaneArrival,
    faGlassCheers,
    faLocationArrow,
    faTaxi,
    faPhoneAlt,
    faLinkedin,
    faArrowCircleLeft,
    faArrowCircleRight,
    // more icons go here
  );