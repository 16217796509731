import React from "react";
import {Row, Col} from 'reactstrap'
import CategoryPickerBanner from "../components/CategoryPickerBanner";
import MediaQuery from 'react-responsive'
import CategoryPickerBannerMobile from "./CategoryPickerBannerMobile";
import centerImage from '../images/logo_v2.webp';

class HomeBanner extends React.Component {

    render()
    {
        return (
            <div id="home" className="header_area" data-scroll-index="0">
                <div className="container">
                    <Row className="header_content">
                        <Col lg={12} md={12}>
                            <div className="header text-center">
                                <div className="banner-welcome">
                                    <img src={centerImage} alt="Centered Image" style={{ maxWidth: '100%', maxHeight: '100%', marginBottom: '10px' }} />
                                    <h1 style={{color: 'black'}}>Zonder zorgen op de baan met <span>Rentorama</span></h1>
                                    <MediaQuery minDeviceWidth={1224}>
                                        <Row className={"justify-content-center"} style={{marginTop: 100}}>
                                            <Col lg={12}>
                                                <CategoryPickerBanner />
                                            </Col>
                                        </Row>
                                    </MediaQuery>
                                    <MediaQuery maxDeviceWidth={1224}>
                                        <Row style={{marginTop: 100}}>
                                            <Col lg={12}>
                                                <CategoryPickerBannerMobile />
                                            </Col>
                                        </Row>
                                    </MediaQuery>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomeBanner;
