// const BASE_URL = 'http://127.0.0.1:5001/rentorama-server-dev/europe-west1/server';
const BASE_URL = 'https://europe-west1-rentorama-server-dev.cloudfunctions.net/server'

export const apiConstants = {

    SERVER_STATUS: BASE_URL+'/',

    // Cars API
    GET_CARS: BASE_URL+'/carsnew',

    // Mail API
    WEB_MESSAGE: BASE_URL+'/webmessage',

    // BOOKING API
    ADD_BOOKING: BASE_URL+'/booking/add',

    // CALCULATE
    CALCULATE: BASE_URL+'/calculate'

}