import React, {lazy, Suspense} from 'react';

import HomeBanner from '../components/HomeBanner';
import Services from '../components/Services';

const renderLoader = () => <p>Loading</p>;
const ContactComponent = lazy(() => import('../components/Contact'));
const FooterComponent = lazy(() => import('../components/MyFooter'));
// const ServiceComponent = lazy(() => import('../components/Services'));

class Home extends React.Component {

    render()
    {
        return(
            <div>
                <HomeBanner />
                <Suspense fallback={renderLoader()}>
                    <ContactComponent />
                    <FooterComponent />
                </Suspense>
            </div>
               
        )
    }
}

export default Home;
