import React from 'react';
import { Link } from 'react-router-dom';

import {
    Row,
    Col,
} from "reactstrap";

import { Card } from "antd";

const Meta = Card.Meta;

class CategoryPickerBanner extends React.Component
{

    render()
    {
        return(
                <Row className={'justify-content-center'}>
                    <Col lg={6} sm={12}>
                        <Link style={{ textDecoration: 'none' }} to={{pathname: `/huren/general`}}>
                        <Card
                            hoverable
                            cover={
                                <img
                                style={{width: "100%",
                                        height: "10vw",
                                        objectFit: "cover"}}
                                  alt="example"
                                  src="/img/nissan_driving.webp"
                                />
                            }
                        >
                            <Meta title="Personenwagen" />
                        </Card>
                        </Link>
                    </Col>
                    <Col lg={6} sm={12}>
                    <Link style={{ textDecoration: 'none' }} to={{pathname: `/huren/premium`}}>
                        <Card
                            hoverable
                            cover={
                                <img
                                style={{width: "100%",
                                        height: "10vw",
                                        objectFit: "cover"}}
                                  alt="example"
                                  src="/img/golf-r8.jpg"
                                />
                            }
                        >
                            <Meta title="Premium Wagen" />
                        </Card>
                    </Link>
                    </Col>
                </Row>
        )
    }
}

export default CategoryPickerBanner;